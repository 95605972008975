html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#page {
  font-size:17px;
  line-height:2.1;
}

.bg-overlay {
  position:relative;
  * {
    position:relative;
    z-index:1;
  }
  &:after {
    content:'';
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background:rgba(0,0,0,0.4);
  }
}

img {
  max-width:100%;
}

.display-1 {
  font-size:45px;
  line-height:1;
  @include media-breakpoint-up(sm) {
    font-size: calc(45px + (75 - 45) * ((100vw - 576px) / (1200 - 576)));
  }
  @include media-breakpoint-up(xl) {
    font-size:75px;
  }
}

.page-title .display-1 {
	font-size:45px;
	line-height:1;
	@include media-breakpoint-up(sm) {
		font-size: calc(45px + (65 - 45) * ((100vw - 576px) / (1200 - 576)));
	}
	@include media-breakpoint-up(xl) {
		font-size:65px;
	}
}

.h1 {
  font-size:45px;
  font-weight:300;
  color:$primary;
  letter-spacing:1px;
  line-height:1.1;
  @include media-breakpoint-up(sm) {
    font-size: calc(45px + (75 - 45) * ((100vw - 576px) / (1200 - 576)));
  }
  @include media-breakpoint-up(xl) {
    font-size:70px;
  }
}

.h2 {
  font-size:30px;
  font-weight:300;
  color:$primary;
  letter-spacing:1px;
  line-height:1.1;
  @include media-breakpoint-up(sm) {
    font-size: calc(30px + (50 - 30) * ((100vw - 576px) / (1200 - 576)));
  }
  @include media-breakpoint-up(xl) {
    font-size:50px;
  }
}

.h3 {
  font-size:24px;
  font-weight:300;
  color:$primary;
  letter-spacing:1px;
  line-height:1.1;
  @include media-breakpoint-up(sm) {
    font-size: calc(24px + (40 - 24) * ((100vw - 576px) / (1200 - 576)));
  }
  @include media-breakpoint-up(xl) {
    font-size:40px;
  }
}

.btn-primary {



  background: transparent !important;
  color: #035BA9;
  border: 0 !important;
  border-bottom: 7px solid #F1B966 !important;
  font-size: 28px;
  font-weight: bold;
  padding: 0;
  border-radius: 0;
  text-transform: uppercase;
  margin-bottom:20px;

  &.btn-inverted {

      border-bottom: 7px solid #FFF !important;
      &:hover {
          border-bottom: 7px solid #F1B966 !important;
      }
  }



  &:hover {
    color:$primary;
    border-color:$primary!important;
  }
  &.dark {
    color:$black;
    border-color:#0F91D0!important;
    &:hover {
      color:#0F91D0;
      border-color:$yellow!important;
    }
  }
  &.white {
    color:$white;
    border-color:$yellow!important;
    &:hover {
	    color:$yellow;
	    border-color:$yellow!important;
    }
  }
  @include media-breakpoint-down(lg) {
    font-size: calc(20px + (28 - 20) * ((100vw - 570px) / (1600 - 570)));
  }
  @include media-breakpoint-down(sm) {
    font-size: 20px;
  }
}

hr {
  border-color:$yellow;
  margin:3rem 0;
}

ul {
  padding-left:20px;
}

.section-1 {
  position:relative;
  .container {
    background:$white;

  }
}

.border-bottom {
  border-color:$yellow!important;
}
