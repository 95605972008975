html {
  scroll-behavior: smooth;
}

#masthead,
.sticky-header {
  position:relative;
  z-index:999;
  a {
    &:hover {
      text-decoration:none;
    }
  }
  .mobile-toggle {
    display:flex;
    flex-direction:column;
    align-items:center;
    cursor:pointer;
    outline:none;
    label {
      margin-bottom:8px;
      line-height:1;
      cursor:pointer;
    }
    .toggle-button {
      width:45px;
      height:35px;
      border:0;
      background:transparent;
      padding:0;
      position:relative;
      outline:none;
      span {
        height:4px;
        width:100%;
        background:$white;
        display:block;
        &.top {
          position:absolute;
          top:0;
          left:0;
        }
        &.bottom {
          position:absolute;
          bottom:0;
          left:0;
          width:50%;
          &:after {
            content:'';
            width:8px;
            height:8px;
            border-radius:50%;
            background:$white;
            display:block;
            position:absolute;
            left:135%;
            bottom:-2px;
          }
        }
      }
    }
  }

  .utility-menu {
	@include media-breakpoint-up(lg) {
	  display:flex;
	  flex-direction:reverse;
	  align-items:center;
	  justify-content:center;
	}
    .nav {
      align-items:center;
      margin-bottom:20px;
      display:none;
      @include media-breakpoint-up(md) {
	      display:flex;
	  }
      @include media-breakpoint-up(lg) {
	      margin-bottom:0;
	      display:flex;
	  }
      li {
        border-right:1px solid $white;
        line-height:1;
        text-transform:uppercase;
        &:last-of-type {
          border-right:0;
          a {
            padding-right:0;
          }
        }
      }
      a {
        color:$white;
        padding:0 5px;
        margin:0 5px;
        line-height:1;
        &:hover {
          color:$yellow;
        }
      }
    }
    .site-logo {
	    img {
		    height:30px;
		    margin-left:30px;
	    }
    }
  }

  .nav-drawer {
    background-size:cover;
    .drawer-nav {
      .nav {
        justify-content:space-between;
        padding-right:40px;
        @include media-breakpoint-down(md) {
          padding-right:0;
        }
        @include media-breakpoint-down(sm) {
          flex-direction:column;
        }
      }
      li {
        font-weight:bold;
        font-size:20px;
        text-transform:uppercase;
        ul {
          list-style:none;
          padding-left:0;
          li {
            a {
              color:$black;
              font-size:18px;
              text-transform:none;
              font-weight:400;
            }
          }
        }
        a {
          color:$primary;
          &:hover {
            color:$yellow;
          }
        }
      }
      .right-nav {
        padding-left:40px;
        border-left:1px solid #FBB755;
        ul {
          list-style:none;
          margin-left:0;
          margin-bottom:0;
          padding-left:0;
          li {
            font-size:18px;
            a {
              color:#80878F;
              &:hover {
                color:$yellow;
              }
            }
          }
        }
        @include media-breakpoint-down(md) {
          padding-left:0;
          border-left:0;
          border-top:1px solid #FBB755;
          padding-top:30px;
          margin-top:30px;
        }
      }
    }
    .close-btn {
      font-size:40px;
      color:$black;
      line-height:1;
    }
    .utility-menu {
      .nav {
        li {
          border-color:$black;
        }
        a {
          color: $black;
        }
      }
    }
  }

  .header-hero {
    min-height:100vh;
    overflow:hidden;
      .display-1 {
          line-height: 1.3 !important;
      }
    .top-bar {
      font-size:15px;
    }
    .main-navigation {
      li {
        font-size:18px;
        text-transform:uppercase;
        a {
          color:$white;
          padding:0 40px;
          &:hover {
            color:$yellow;
          }
        }
        &:last-of-type {
          a {
            padding-right:0;
          }
        }
      }
    }
    .nav-bar {
      padding-bottom:130px!important;
    }
    .down-arrow {
      position:absolute;
      font-size:50px;
      color:$yellow;
      left:auto;
      right:auto;
      bottom:20px;
      width:100%;
      text-align:center;
    }
  }

  .default-header {
    .utility-menu {
      li {
        border-color:$black;
      }
      a {
        color:$black;
      }
    }
    .toggle-label {
      color:$blue;
    }
    .toggle-button {
      span {
        background:$blue;
      }
    }
  }
}

.sticky-header {
  position:fixed;
  top:0;
  left:0;
  width:100%;
  background:#DFE1E2;
  max-height:100vh;
  overflow:auto;
  visibility:hidden;
  opacity:0;
  transition:opacity 0.75s ease-in-out;

  .toggle-label {
    color:$primary!important;
  }
  .toggle-button {
    span {
      background:$primary!important;
      &:after {
        background:$primary!important;
      }
    }
  }
}
.scrolled {
  .sticky-header {
    visibility:visible;

  }
}
.fixed {
  .sticky-header {
    opacity:1;
    transition:opacity 0.75s ease-in-out;
  }
}

.social-strip {
  display:flex;
  list-style:none;
  padding-left:0;
  margin-left:0;
  li {
    font-size:28px;
    line-height:1;
    margin-right:20px;
    a {
      color:$primary;
      width:40px;
      height:40px;
      border:1px solid $primary;
      line-height:1;
      display:block;
      display:flex;
      align-items:center;
      justify-content:center;
      line-height:1;
      padding:10px 10px 7.5px 10px;
      text-decoration:none!important;
      outline: none !important;
      box-shadow: none !important;
      &:hover {
        color:$yellow;
        border-color:$yellow;
      }
    }
  }
}

.floater {
  position:absolute;
  width:75px;
  height:75px;
  border-radius:50%;
  border:2px solid $yellow;
  cursor:pointer;
  transition:all 0.5s ease-in-out;
    transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;

    &:before {
    content:'';
    width:26px;
    height:26px;
    border-radius:50%;
    border:2px solid $yellow;
    position:absolute;
    top:calc(50% - 13px);
    left:calc(50% - 13px);
  }
  .flag-content {
    width:250px;
    height:180px;
    background-size:cover;
    background-position:left bottom;
    background-image:url(/vendor/erland/images/floating-flag.svg);
    position:absolute;
    bottom:50%;
    left:50%;
    visibility:hidden;
    opacity:0;
    z-index:9999;
    padding:15px;
    text-align:center;
  }
  .content {
      font-size: 23px;
      display: block;
      line-height: 1.7;
      font-weight: 300;
      text-overflow: ellipsis;
      white-space: nowrap;
  }
  .sub-content {
	  font-size:22px;
	  color:$primary;
	  line-height:1;
	  display:block;
  }
  .arrow  {
	  display:block;
	  position:absolute;
	  bottom:50px;
	  left:0;
	  text-align:center;
	  width:100%;
  }
  &:hover {
    background: rgb(251,183,85);
    background: -moz-radial-gradient(circle, rgba(251,183,85,0) 0%, rgba(251,183,85,1) 100%);
    background: -webkit-radial-gradient(circle, rgba(251,183,85,0) 0%, rgba(251,183,85,1) 100%);
    background: radial-gradient(circle, rgba(251,183,85,0) 0%, rgba(251,183,85,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fbb755",endColorstr="#fbb755",GradientType=1);
    &:before {
      background:rgba($black, 0.25);
    }
    .flag-content {
      visibility:visible;
      opacity:1;
      transition:all 0.15s ease-in-out;
    }
  }
  &#floater1 {
    top:245px;
    left:40vw;
    animation: ease-in-out swayLeft 60s infinite, ease-in-out upDown 60s infinite;
    animation-duration: 50s;
  }
  &#floater2 {
    top:35vh;
    right:20vw;
    transform:scale(0.65);
      animation: ease-in-out swayRight 60s infinite, ease-in-out upDown 60s infinite;
      animation-duration: 75s;
    .flag-content {
	    transform:scale(1.65)!important;
	    left:165%;
	    bottom:135%;
    }
  }
  &#floater3 {
    top:65vh;
    right:25vw;
      animation: ease-in-out swayRight 60s infinite, ease-in-out upDown 60s infinite;
      animation-duration: 90s;
  }
  &#floater4 {
    top:60vh;
    left:35vw;
    transform:scale(0.65);
      animation: ease-in-out swayRight 60s infinite, ease-in-out upDown 60s infinite;
      animation-duration: 55s;
    .flag-content {
	    transform:scale(1.65)!important;
	    left:165%;
	    bottom:135%;
    }
  }
  @include media-breakpoint-down(sm) {
    display:none;
  }
}

@keyframes swayLeft {
   0%, 100%{
     margin-left:-15vh;
   }
   50%{
     margin-left:15vh;
   }
}
@keyframes upDown {
  0%, 100%{
    margin-top:-10vh;
  }
  50%{
    margin-top:10vh;
  }
}
@keyframes swayRight {
  0%, 100%{
    margin-right:-15vh;
  }
  50%{
    margin-right:15vh;
  }
}
