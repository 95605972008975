.page-hero {

  &.default {
    min-height:46vw;
    background-size:cover;
    background-position:center;
    background:$primary;
    margin-bottom:-150px;
    @include media-breakpoint-down(md) {
      min-height:400px
    }
  }
}

// HEADING LEFT - CONTENT RIGHT
.heading-l-content-r {
  .container {
    background:$white;
    position:relative;
  }
  .border-bottom {
    border-color:$yellow!important;
  }
}

// TESTIMONIAL SLIDER
.testimonial-slider {
  position:relative;
  .image {
    img {
      width:100%;
    }
  }
  .testimonial-slide {
    p:last-of-type {
     margin-bottom:0;
    }
  }
  .slick-slider {
    position:relative;
    .slick-arrow {
      position:absolute;
      top:-80px;
      right:0;
      font-size:40px;
      color:#80878F;
      cursor:pointer;
      z-index:999;
      &.slick-prev {
        right:60px;
      }
    }
    .slick-dots {
      list-style:none;
      padding-left:0;
      margin-bottom:0;
      position:absolute;
      bottom:0;
      left:-36px;
      button {
        font-size:0;
        border-radius:50%;
        width:15px;
        height:15px;
        background:$white;
        border:1px solid #80878F;
        outline:none;
      }
      .slick-active {
        button {
          background:$yellow;
          border-color:$yellow;
        }
      }
      @include media-breakpoint-down(md) {
        bottom:0px;
        left:0;
        width:100%;
        text-align:center;
        display:flex;
        justify-content:center;
        button {
          margin:10px;
        }
      }
    }
  }
}

.hide-arrows {
  .slick-arrow {
    display:none!important;
  }
}
.inline-arrows {
  .slick-arrow {
    top:0!important;
  }
}

// LABEL DIVIDER
.label-divider {
  .label {
    display:flex;
    align-items:center;
    position:relative;
    color:$primary;
    font-size:22px;
    font-weight:bold;
    text-transform:uppercase;
    span,
    a {
        padding: 0 10px 0 40px;
    }
    &:before {
      content:'';
      width:70vw;
      height:2px;
      background:$yellow;
      display:block;
    }
    &:after {
      content:'';
      width:10px;
      height:10px;
      background:$yellow;
      display:block;
      border-radius:50%;
      position:absolute;
      left:calc(70vw + 10px);
    }
  }
  @include media-breakpoint-down(lg) {
    .label {
      font-size:14px;
      &:before {
        width:50vw;
          @media(max-width: 380px)
          {
              width: 10vw;
          }
      }
      &:after {
        left:calc(50vw + 10px);
        @media(max-width: 380px)
        {
            left: calc(10vw + 10px);
        }
      }
    }
  }
}

// WORK GRID
.work-grid {
  .projects-row {
    display:flex;
    flex-wrap:wrap;
    justify-content:space-between;
    margin-bottom:45px;
    .project {
      position:relative;
      overflow:hidden;
      &:first-of-type {
        padding-top: 36%;
        width: calc(20% - 45px);
      }
      &:nth-of-type(2) {
        width: 55%;
        display:flex;
        flex-wrap:wrap;
        justify-content:space-between;
        .project-inner {
          &:first-of-type {
            width: 100%;
            height: calc(50% - 45px);
          }
          &:nth-of-type(2) {
            width:40%;
            height:50%;
            margin-top:auto;
          }
          &:nth-of-type(3) {
            width:calc(60% - 45px);
            height:50%;
            margin-top:auto;
          }
        }
      }
      &:nth-of-type(3) {
        width: calc(25% - 45px);
        display:flex;
        flex-wrap:wrap;
        justify-content:space-between;
        .project-inner {
          &:first-of-type {
            width: 100%;
            height: calc(50% - 45px);
          }
          &:nth-of-type(2) {
            height:50%;
            margin-top:auto;
            width:100%;
          }
        }
      }
    }
    .project-inner {
      position:relative;
      overflow:hidden;
    }
    @include media-breakpoint-down(md) {
      margin-bottom:0;
      .project {
        width:100%!important;
        min-height:250px;
        margin-bottom:45px;
        .project-inner {
          height:300px!important;
          &:first-of-type {
            margin-bottom:45px;
          }
        }
      }
    }
  }
  .project img {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    object-fit:cover;
    transition:all 0.5s ease-in-out;
    &:hover {
      transform:scale(1.025);
      opacity:0.8;
    }
  }
}

// LARGE TESTIMONIAL
.large-testimonial {
  .testimonial {
    position:relative;
    width:991px;
    max-width:100%;
    margin-left:auto;
    margin-right:auto;
    img {
      margin-bottom:-20px;
      @media (max-width:768px) {
        margin-bottom:20px;
      }
    }
    .h2 {

    }
    h4 {
      line-height:0.8!important;
    }
  }
}

// ERLAND FOUNDATION
.erland-foundation {
  .image {
    img {
      height:100%;
      width:100%;
      object-fit:cover;
    }
  }
  .content {
    background-size:cover;
    background-position:center right;
    padding:60px;
  }
}

// LOGO STRIP
.logo-strip {
  .logos {
    flex-wrap:wrap;
    align-items:center;
    justify-content:center;
    .logo {
      //width:20%;
      max-height: 130px;
      object-fit: contain;
      padding: 15px 20px;
      @include media-breakpoint-down(sm) {
        width:33.33%;
      }
    }
  }
}

// FEATURE TABLE
.feature-table {
  .container {
    padding-left:40px;
    padding-right:40px;
  }
  .col-3 {
    padding-left:10px;
    padding-right:10px;
  }
  h2 {
    background:#375475;
    padding:15px;
    text-align:center;
    margin-bottom:0;
  }
  .table-content {
    background: rgb(221,222,222);
    background: -moz-linear-gradient(0deg, rgba(221,222,222,0) 0%, rgba(221,222,222,1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(221,222,222,0) 0%, rgba(221,222,222,1) 100%);
    background: linear-gradient(0deg, rgba(221,222,222,0) 0%, rgba(221,222,222,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#dddede",endColorstr="#dddede",GradientType=1);
    min-height:100px;
      height:auto;
      font-size:.9em;
      line-height:1.6;
  }

}


// ERLAND TABS
.erland-tabs {
  nav {
    border-bottom:1px solid #035aa9;;
  }

    .tab-pane {
        .container {
            padding-right:0px !important;
            padding-left:0px !important;
        }
    }

}
.nav-tabs {

  @media(max-width:767px)
  {
      border-bottom:none !important;
  }
  .nav-link {
    background:#DDDEDE;
    margin-left:5px;
    margin-right:5px;
    color:#80878F;
    border-radius: 10px 10px 0 0;

    @media(max-width:767px)
    {
        flex:auto;
        width:100%;

        border-bottom-left-radius:0 !important;
        border-bottom-right-radius:0 !important;
        border-top-left-radius:0 !important;
        border-top-right-radius:0 !important;
        margin-left: 0;
        margin-right: 0;

        border:none;
        border-bottom:1px solid #515151 !important;
        margin-bottom:0 !important;

        &:first-child {
            border-top-left-radius:10px !important;
            border-top-right-radius:10px !important;
        }

        &:last-child {
            // border-bottom-left-radius:10px;
            // border-bottom-right-radius:10px;
            border-bottom:none !important;
        }
    }


    &.active {
      background:$primary;
      color:$white;
      border-radius: 10px 10px 0 0;
      border-bottom:0;
    }
    &:first-of-type {
      margin-left:0;
    }
    &:last-of-type {
      margin-right:0;
    }
  }
}

// ERLAND CALLOUTS
.erland-callouts {
  h4 {
    color:$primary;
    text-transform:uppercase;
    margin-bottom:1em;
    padding-bottom:1em;
    position:relative;
    &:after {
      content:'';
      width:145px;
      margin:0 auto;
      height:1px;
      background:$yellow;
      position:absolute;
      bottom:0;
      left:0;
      right:0;
    }
  }
}

// ERLAND ACCORDION
.erland-accordion {
  .collapse-title {
    background:transparent;
    width:100%;
    border:0;
    border-bottom:1px solid $yellow;
    padding:15px 0;
    text-align:left;
    outline:none!important;
    font-size:35px;
    font-weight:300;
    transition:all 0.15s ease-in-out;
    position:relative;
    &:after {
      content:'\f067';
      font-family:'Font Awesome 5 Pro';
      font-weight:300;
      color:$primary;
      margin-left:auto;
      position:absolute;
      height:100%;
      display:flex;
      align-items:center;
      right:0;
      top:0;
    }
    &[ aria-expanded="true" ] {
      font-weight:bold;
      color:$primary;
      &:after {
        content:'\f068';
      }
    }
  }
  .collapse-content {
    padding:3rem 0;
  }
}


// ERLAND TEAM
.erland-team {
  .team-nav {
    margin:10px;
    border-bottom:1px solid $primary;
    li {
      margin-right:20px;
      &:last-of-type {
        margin-right:0;
      }
      button {
        text-transform:uppercase;
        font-size:20px;
        background:#DDDEDE;
        color:#80878F;
        border:0;
        border-radius:10px 10px 0 0;
        outline:none;
        &.active {
          background:$primary;
          color:$white;
        }
      }
      @include media-breakpoint-down(lg) {
        margin-right:10px;
        button {
          font-size:16px;
        }
      }
    }
    @include media-breakpoint-down(lg) {
      margin:5px;
    }
  }
  .team-grid {
      display:flex;
    flex-wrap:wrap;

      &.foundation {
          .team-member {
              margin-bottom: 200px;
          }
      }
    .team-member {
      width: calc(33.33% - 20px);
      margin:10px;
      background:#DBDADA;
      position:relative;
      img {
          width: 100%;
          object-fit: cover;
          max-height: 400px;
          min-height: 100%;
          object-position: center center;
          height:100%;
      }
      .team-info {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        background:rgba(0,0,0,0.45);
        padding:15px;
        text-align:center;
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
        color:$white;
        line-height:1.4;
        font-size:24px;
        cursor:pointer;
        opacity:0;
        transition:opacity 0.35s ease-in-out;
          &.foundation {
              opacity: 1;
              top: 100%;
              bottom: 0px;
              height: 180px;
              background: none;
              color: #000;
              justify-content: start;
          }

        .name {
          display:block;
          font-weight:bold;
        }
        .title {
          display:block;
          font-style:italic;
        }
      }
      &:hover {
        .team-info {
          opacity:1;
        }
      }
      @include media-breakpoint-down(lg) {
        width: calc(50% - 10px);
        margin:5px;
      }
      @include media-breakpoint-down(md) {
        .team-info {
          font-size:16px;
        }
      }
    }
  }
}

.team-bio {
  background: rgb(193,197,199);
  background: -moz-linear-gradient(180deg, rgba(193,197,199,1) 0%, rgba(247,247,247,1) 100%);
  background: -webkit-linear-gradient(180deg, rgba(193,197,199,1) 0%, rgba(247,247,247,1) 100%);
  background: linear-gradient(180deg, rgba(193,197,199,1) 0%, rgba(247,247,247,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#c1c5c7",endColorstr="#f7f7f7",GradientType=1);
    width: 75%;
position: relative;
height: 85vh;
max-height: 650px;
  position:relative;
    padding:0px;
  @include media-breakpoint-down(sm) {
    padding:0;
  }
  .fancybox-button {
    width:60px;
    height:60px;
    color:#000000;
  }
  .team-photo {
    position:absolute;
    top:0;
    left:0;
    width:30%;
    height:100%;
    object-fit:cover;
    @include media-breakpoint-down(sm) {
      position:relative;
      max-height:250px;
      margin-left:35%;
    }
  }
  .team-name {
    font-size:30px;
    font-weight:bold;
    color:$primary;
  }
  .team-title {
    font-size:24px;
    font-style:italic;
    color:#393838;
    margin-bottom:20px;
  }
  .team-bio-content {
    border-top:1px solid $primary;
    padding-top:20px;
    color:#393838;
  }
  .team-contact {
	  display:flex;
	  align-items:center;
    .connect {
      color:$yellow;
      font-weight:bold;
      i {
        color:$black;
        margin-right:15px;
      }
    }
    a {
      color:$primary;
      font-size:24px;
      outline:none;
    }
    .social-icon {
	    height:35px;
	    width:35px;
	    display:flex;
	    align-items:center;
	    justify-content:center;
	    border:1px solid $primary;
	    line-height:1;
	    text-decoration:none;
    }
  }

  .team-content {
      height: 100%;
      margin-left: 30%;
      padding: 3% 5% 5% 4%;
      position: absolute;
      overflow: scroll;
    @include media-breakpoint-down(sm) {
      margin-left:0;
      border-top:4px solid $black;
    }
  }
}


// PROJECT QUICK STATS
.project-quick-stats {
  background-size:cover;
  ul {
    list-style:none;
    padding-left:0;
    font-size:27px;
    line-height:1.45;
    li {
      margin-bottom:20px;
    }
  }
}

.project-gallery {
  .project-photos {
    position:relative;
    .slick-arrow {
      position:absolute;
      top:calc(50% - 20px);
      right:5%;
      font-size:60px;
      color:#80878F;
      cursor:pointer;
      z-index:999;
      color:$white;
      &.slick-prev {
        left:5%;
      }
    }
    .slick-dots {
      list-style:none;
      padding-left:0;
      margin-bottom:0;
      position:absolute;
      bottom:5%;
      left:0px;
      width:100%;
      text-align:center;
      display:flex;
      justify-content:center;
      button {
        font-size:0;
        border-radius:50%;
        width:15px;
        height:15px;
        background:$white;
        border:0;
        outline:none;
        margin:10px;
      }
      .slick-active {
        button {
          background:$primary;
        }
      }
    }
  }
}

// NEWS LIST
.news-list {
  margin-left:10px;
  margin-right:10px;
  @include media-breakpoint-down(sm) {
    margin-left:5px;
    margin-right:5px;
  }
  hr {
    margin-top:0;
    margin-bottom:10px;
  }
  p{
    line-height:1.5;
    font-size:20px;
  }
  a {
    color:black;
    text-decoration:none!important;
    display:block;
    position:relative;
    &:before {
      content:'';
      width:40px;
      height:25px;
      background-image:url('/assets/images/news-arrow-right.svg');
      background-size:cover;
      display:block;
      position:absolute;
      left:-84px;
      bottom:calc(50% - 12.5px);
      transition:all 0.35s ease-in-out;
      opacity:0;
    }
    &:hover {
      &:before {
        left:-64px;
        opacity:1;
      }
    }
  }
}

// PROJECT GRID
.project-archive {
  .project-archive-grid {
    display:flex;
    flex-wrap:wrap;
    .project {
      width:calc(33.33% - 10px);
      margin:5px;
      padding-top:20%;
      position:relative;
      img {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        object-fit:cover;
        z-index:0;
      }
      .project-info {
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        padding:20px;
        display:flex;
        justify-content:center;
        align-items:center;
        text-align:center;
        z-index:1;
        background-color:rgba($black, 0.5);
        color:$white;
        font-weight:bold;
        opacity:0;
        transition:opacity 0.35s ease-in-out;
        line-height:1.3;

          > span {
              display: block;
              position: absolute;
          }

          > span.project-title {
              top: calc(50% - 45px);
          }

          > span.project-location {
              top: calc(50% + 5px);
          }

      }
      &:hover {
        .project-info {
          opacity:1;
        }
      }
      @include media-breakpoint-down(lg) {
        width:calc(50% - 10px);
        padding-top:30%;
      }
      @include media-breakpoint-down(sm) {
        width:calc(100% - 10px);
        padding-top:60%;
      }

    }
  }
}
.form-check-input {
  margin-top:0.6rem;
}


/*
.erland-timeline {
  position:relative;
  z-index:999999;
}

#timeline-nav {
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  padding-top:60px;
  padding-bottom:60px;
  .list-group-item {
    z-index:1;
    background:transparent;
    color:$white;
    border:0;
    .timeline-content {
      display:none;
    }
    &.active {
      .timeline-content {
        display:block!important;
      }
    }
  }
}

.timeline-container {
  height:1080px;
  min-height:100vh;
  background:#ededed;
  overflow: scroll;
}

.timeline-item {
  height:100%;
  background:transparent;
  &:after {
    content:'';
    position:absolute;
    background: rgb(3,91,169);
    background: -moz-linear-gradient(90deg, rgba(3,91,169,1) 0%, rgba(3,91,169,0) 100%);
    background: -webkit-linear-gradient(90deg, rgba(3,91,169,1) 0%, rgba(3,91,169,0) 100%);
    background: linear-gradient(90deg, rgba(3,91,169,1) 0%, rgba(3,91,169,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#035ba9",endColorstr="#035ba9",GradientType=1);
    top:0;
    left:0;
    width:100%;
    height:100%;
    opacity:0.5;
  }
  &:nth-of-type(2n) {
    &:after {
      background: rgb(228,104,62);
      background: -moz-linear-gradient(90deg, rgba(228,104,62,1) 0%, rgba(228,104,62,0) 100%);
      background: -webkit-linear-gradient(90deg, rgba(228,104,62,1) 0%, rgba(228,104,62,0) 100%);
      background: linear-gradient(90deg, rgba(228,104,62,1) 0%, rgba(228,104,62,0) 100%);
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e4683e",endColorstr="#e4683e",GradientType=1);
    }
  }
}
*/

.erland-timeline {
    position:relative;
    z-index:99999;
}

#timeline-nav {
    z-index:1;
    .list-group-item {
        z-index:1;
        background:transparent;
        color:$white;
        border:0;
        .timeline-content {
            display:none;
        }
        &.active {
            .timeline-content {
                display:block!important;
            }
        }
    }
}


#timeline-nav {
    display:none;

    &.is-visible {
        display:block;
    }
}

#timeline-nav-list {
    border-left:1px solid $white;
    min-height:100vh;

    .list-group-item:first-of-type {
        margin-top:5vh;
    }

    .list-group-item:last-of-type {
        margin-bottom: auto;
    }

    .list-group-item {
        padding-left:50px;
        font-size:20px;
        transition:all 0.25s ease-in-out;

        h3 {
            position:relative;

            &:before {
                content:'';
                width:38px;
                position:absolute;
                left:-50px;
                top:50%;
                height:2px;
                background:#fff;
                transition:all 0.25s ease-in-out;
            }
        }
    }

    .list-group-item.active {
        padding-left:100px;

        line-height:1.5;
        h3 {
            font-size:55px;
            font-weight:bold;
            &:before {
                width:80px;
                left:-100px;
                transition:all 0.25s ease-in-out;
            }
        }
    }
}

.timeline-item {
    height:100vh;
    background:transparent;
    &:after {
        content:'';
        position:absolute;
        background: rgb(3,91,169);
        background: -moz-linear-gradient(90deg, rgba(3,91,169,1) 0%, rgba(3,91,169,0) 100%);
        background: -webkit-linear-gradient(90deg, rgba(3,91,169,1) 0%, rgba(3,91,169,0) 100%);
        background: linear-gradient(90deg, rgba(3,91,169,1) 0%, rgba(3,91,169,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#035ba9",endColorstr="#035ba9",GradientType=1);
        top:0;
        left:0;
        width:100%;
        height:100%;
        opacity:0.5;
    }
    &:nth-of-type(2n) {
        &:after {
            background: rgb(228,104,62);
            background: -moz-linear-gradient(90deg, rgba(228,104,62,1) 0%, rgba(228,104,62,0) 100%);
            background: -webkit-linear-gradient(90deg, rgba(228,104,62,1) 0%, rgba(228,104,62,0) 100%);
            background: linear-gradient(90deg, rgba(228,104,62,1) 0%, rgba(228,104,62,0) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#e4683e",endColorstr="#e4683e",GradientType=1);
        }
    }
}


// SINGLE PROJECT IMAGES
.project-gallery {

    .slick-slide {

        .project-img {
            img {
                max-height: 100vh;
                min-height: 100vh;
                object-fit: contain;
                height: 100vh;
                object-position: center center;
            }
        }
    }

}
