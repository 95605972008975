.site-footer {
  padding-top:100px;
  padding-bottom:50px;
  background: #375475;
  background: -moz-linear-gradient(180deg, #375475 0%, #1c2a3b 100%);
  background: -webkit-linear-gradient(180deg, #375475 0%, #1c2a3b 100%);
  background: linear-gradient(180deg, #375475 0%, #1c2a3b 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#375475",endColorstr="#1c2a3b",GradientType=1);
  color: #fff;

  .footer-menu {
    list-style:none;
    margin-left:0;
    padding-left:0;
    li {
      margin-bottom:15px;
    }
    a {
      color:$white;
      font-size:20px;
      text-transform:uppercase;
    }
  }

  .social-strip {
    a {
      color:$white;
      border-color:$white;
      &:hover {
        color:$yellow;
        border-color:$yellow;
      }
    }
  }

  form {
    input,
    textarea {
      background:transparent;
      border-radius:0;
      border:0;
      border-bottom:1px solid #DBDADA;
      padding-left:5px;
      &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        color: $white;
      }
      &::-moz-placeholder { /* Firefox 19+ */
        color: $white;
      }
      &:-ms-input-placeholder { /* IE 10+ */
        color: $white;
      }
      &:-moz-placeholder { /* Firefox 18- */
        color: $white;
      }
    }
  }
}

.footer-cta {
  padding-top:200px;
  padding-bottom:100px;
  background-size:cover;
  background-position:center center;

    h2 {
        line-height:60px;
    }

}
