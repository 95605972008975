@import 'bootstrap/bootstrap';

// PLUGINS
@import 'plugins/jarallax';
@import 'plugins/slick';
//@import 'plugins/slick-theme';

// PARTIALS
@import 'partials/general';
@import 'partials/header';
@import 'partials/footer';
@import 'partials/modules';

.site-header .bg-overlay .carousel-item img {
    min-height: 100vh;
    object-fit:cover !important;
}

.drawer-nav .right-nav li {
    line-height: 1.5;
    margin-bottom: 12px;
    margin-top: 8px;
}


.erland-callout > a.event-gallery-link {
    position: relative;
    display: inline-block;
    margin-bottom: 3rem!important;
    transition: transform ease-in-out 0.2s;
    transform: scale(1, 1);

    &:hover {
        transform: scale(1.03, 1.03);
    }

    .overlay {
        opacity: 0;
        background: #00000033;
        position: absolute;
        top: 10px;
        right: 10px;
        left: 10px;
        bottom: 10px;
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        border-radius: 1000%;
        color: #fff;
        display: flex;
        align-items: center;
        flex-direction: column;
        transition: opacity .2s ease-in-out;

        svg {
            width: 40px;
            margin-top: auto;
            margin-bottom: auto;
            box-shadow: #00000061 0 0 20px 20px;
        }
    }

    &:hover .overlay {
        opacity:1;
    }
}


div#search-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background:#fff0;

    label {
        display:none;
    }

    #search {
        margin-top:50px;
    }

    button.fancybox-button {

        &.fancybox-close-small {
            transform: scale(1.5, 1.5);
        }

        svg path {
            color: #FFF !important;
        }

    }


}

.project-details a.btn.btn-primary {
    margin-bottom: -5px;
}

/* Limit size of logo on blog podcast page */
.blog-podcast .image-left-content-right .image {
    max-width: 400px;
}

.featured-image {
    width: 100%;
    height: 350px;
    display: flex;
    margin-top: 25px;
    img {
        object-fit:cover;
    }
}


.news-list .featured-image {
    margin-top: -5px;
    height: 300px;
    margin-bottom: 30px;
}
